import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import biyidp from "../images/biyid.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default function AdminLogin() {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility
  const [email, setEmail] = useState(''); // State for email input
  const [password, setPassword] = useState(''); // State for password input
  const [error, setError] = useState(''); // State for error handling
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    // Prepare the body for the POST request
    const body = {
      email: email,
      password: password
    };

    try {
      // Make the API call to your endpoint
      const response = await fetch('https://ziggy-backend-ffco.onrender.com/v1/api/admin-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      const data = await response.json();

      // Check if login was successful
      if (response.ok) {
        // Store token and userId in localStorage
        localStorage.setItem('token', data.token);
        localStorage.setItem('userId', data.user._id);

        // Navigate to Portfoliopage
        navigate('/Portfoliopage');
      } else {
        // Handle error if login fails
        setError(data.message || 'Login failed');
      }
    } catch (error) {
      // Handle any network errors
      setError('An error occurred. Please try again.');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible); // Toggle the password visibility
  };

  return (
    <div>
      <h1 className="pass-subheading">Login</h1>
      <form onSubmit={handleFormSubmit} className="admin_login_form">
        <figure>
          <img src={biyidp} alt="Admin" />
        </figure>
        {error && <p className="error-message">{error}</p>} {/* Display error message */}
        <input
          type="email"
          placeholder='Enter Username'
          value={email}
          onChange={(e) => setEmail(e.target.value)} // Update email state
        />
        <div className="pass-space">
          <input
            type={passwordVisible ? "text" : "password"} // Toggle input type
            placeholder='Enter Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Update password state
          />
          <FontAwesomeIcon
            icon={passwordVisible ? faEye : faEyeSlash} // Toggle icon
            onClick={togglePasswordVisibility}
            style={{ cursor: 'pointer' }} // Add cursor style for better UX
          />
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Access'}
        </button>
      </form>
    </div>
  );
}
